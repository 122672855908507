/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosRequestConfig } from "axios";
import axiosInstance from "./axiosInstance";

export function getFetcher<T extends unknown = unknown>(
  url: string,
  config?: AxiosRequestConfig
) {
  return axiosInstance.get<T>(url, config).then((res) => res.data);
}

export function putFetcher<T extends unknown = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.put<T>(url, data, config).then((res) => res.data);
}

export function patchFetcher<T extends unknown = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.patch<T>(url, data, config).then((res) => res.data);
}

export function postFetcher<T extends unknown = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.post<T>(url, data, config).then((res) => res.data);
}

export function deleteFetcher<T>(url: string, config?: AxiosRequestConfig) {
  return axiosInstance.delete<T>(url, config).then((res) => res.data);
}
