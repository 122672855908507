import { getMemberInfo } from "@src/apis/services/memberService";
import type { GetMemberInfoResult } from "@src/types/memberType";
import type { CustomQueryOptions } from "@src/types/reactQueryType";
import { queryOptions, useQuery } from "@tanstack/react-query";

export type MemberInfoQueryOptions = CustomQueryOptions<GetMemberInfoResult>;

export const memberInfoQueryOptions = (options?: MemberInfoQueryOptions) =>
  queryOptions({
    queryKey: ["member", "info"],
    queryFn: () => getMemberInfo(),
    ...options
  });

export const useMemberInfoQuery = (options?: MemberInfoQueryOptions) => {
  const {
    data: memberInfo,
    isLoading: memberInfoLoading,
    error: memberInfoError,
    refetch: refetchMemberInfo
  } = useQuery(memberInfoQueryOptions(options));

  return { memberInfo, memberInfoLoading, memberInfoError, refetchMemberInfo };
};
